import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.77 18.77"
      {...props}
    >
      <defs>
        <style>
          {
            ".cls-wclose-1{fill:none;stroke:#fff;stroke-miterlimit:10;strokeWidth:1.5px;}"
          }
        </style>
      </defs>
      <g className="cls-wclose-1" data-name="Layer 2">
        <path
          d="m2.53 2.53 12.71 12.71m0-12.71L2.53 15.24"
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
