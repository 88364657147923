import Button from "../../Button";
import Label from "../../Label";
import {
  ChevronLeftIcon,
  ChevronLeftActiveIcon,
  ChevronRightIcon,
  ChevronRightActiveIcon,
} from "@assets/svgs";

export const Pagination = ({
  currentPage,
  totalPages,
  handlePrevPage,
  handleNextPage,
}) => (
  <div className="leader-board-pagination">
    <Button
      styles="leader-board-pagination__btn-prev"
      disabled={currentPage === 1 || currentPage === 0}
      onClick={handlePrevPage}
    >
      {currentPage === 1 || currentPage === 0 ? (
        <ChevronLeftIcon />
      ) : (
        <ChevronLeftActiveIcon />
      )}
    </Button>
    <Label type="primary" size="small">
      Page {currentPage} of {totalPages}
    </Label>
    <Button
      styles="leader-board-pagination__btn-next"
      disabled={currentPage === totalPages}
      onClick={handleNextPage}
    >
      {currentPage === totalPages ? (
        <ChevronRightIcon />
      ) : (
        <ChevronRightActiveIcon />
      )}
    </Button>
  </div>
);
