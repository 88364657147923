import React from 'react';
import Label from '../Label';
import { ArrowDownIcon } from '@assets/svgs';
import Menu from '../Menu';
import Button from '../Button';

export default function ButtonDropdown({
  selected,
  styles,
  menu,
  open,
  position,
  property,
  wrapperRef,
  onToggle,
  onItemClick,
}) {
  const selectedItem = menu.find((item) => item.value === selected);

  return (
    <div className={`drop-down ${styles ? styles : ''}`}>
      <Button
        border="round"
        type="white"
        size="small"
        styles="btn-transparent-white"
        onClick={onToggle}
      >
        <Label styles="drop-down__toggle__label" type="primary" size="small">
          {selectedItem?.label}
        </Label>
        <ArrowDownIcon width={20} height={10} />
      </Button>
      {open && (
        <Menu
          selected={selected}
          menu={menu}
          open={open}
          position={position}
          property={property}
          wrapperRef={wrapperRef}
          onToggle={onToggle}
          onItemClick={onItemClick}
        />
      )}
    </div>
  );
}
