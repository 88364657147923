import React, { useEffect, useRef, useState } from "react";

import Button from "../../Button";
import Label from "../../Label";
import Title from "../../Title";
import { Avatar } from "../Avatar";
import { LogoIcon, CloseBlackIcon, BlackDetailIcon } from "@assets/svgs";

import { TabCarousel } from "../../TabCarousel";
import Dropdown from "../../Dropdown";
import {
  BTRST_EARNERS_FILTERS,
  DEFAULTS_EARNERS_FILTERS_VALUE,
  SORT_ORDER_FILTER_OPTIONS,
  TIME_FILTER_OPTIONS,
  useEarnersList,
} from "../../../hooks/earners-list";
import { Loader } from "../../Loader";
import { Pagination } from "./pagination";

import "./style.scss";

export function BTRSTLeaderBoard({ tokenEarnerYears }) {
  const {
    filters,
    updateFilter,
    totalNumberOfReferrers,
    currentPage,
    totalPage,
    handlePrevPage,
    handleNextPage,
    earnerList,
  } = useEarnersList();
  const tokenEarnerYearsAsOptions =
    tokenEarnerYears?.length > 0
      ? tokenEarnerYears
          ?.map((option) => ({
            label: option,
            value: option,
          }))
          .sort((a, b) => b.value - a.value)
      : [];
  const tokenYearsOption = [
    ...TIME_FILTER_OPTIONS,
    ...tokenEarnerYearsAsOptions,
  ];
  const [isTimeFilterOpen, setTimeFilterOpen] = useState(false);
  const [isSortFilterOpen, setSortFilterOpen] = useState(false);
  const [showIntro, setShowIntro] = useState(false);
  const timeFilterRef = useRef();
  const sortRef = useRef();

  const onToggle = () => {
    setShowIntro(!showIntro);
  };

  const handleClickOutside = (event) => {
    if (
      isTimeFilterOpen &&
      timeFilterRef &&
      !timeFilterRef.current.contains(event.target)
    )
      setTimeFilterOpen(false);
    if (isSortFilterOpen && sortRef && !sortRef.current.contains(event.target))
      setSortFilterOpen(false);
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSortFilterOpen, isTimeFilterOpen]);

  return (
    <div
      className="leader-board leader-board--red"
      data-aos="fade-up"
      data-aos-delay="1"
    >
      <div
        className={`leader-board__main leader-board__main--${
          showIntro ? "hide" : "show"
        }`}
      >
        <div className="leader-board__header">
          <div className="leader-board__title-and-cta">
            <Title
              styles="leader-board__title"
              type="primary"
              size="small"
              font="medium"
            >
              {totalNumberOfReferrers} referrers
            </Title>
            <Button
              border="round"
              type="primary"
              size="small"
              styles="leader-board__join-button"
              onClick={() => {
                return (window.location.href = `${
                  import.meta.env.VITE_REACT_APP_BRAINTRUST_URL
                }refer-clients`);
              }}
            >
              Join as connector
            </Button>
          </div>
          <Button onClick={onToggle}>
            <BlackDetailIcon className="icon__detail" />
          </Button>
        </div>
        <div>
          <TabCarousel
            list={BTRST_EARNERS_FILTERS}
            selected={
              filters?.order.charAt(0) === "-"
                ? filters?.order.slice(1)
                : filters?.order
            }
            property="order"
            onTabItemClick={updateFilter}
          />
          <div className="leader-board__filter">
            <Dropdown
              open={isTimeFilterOpen}
              selected={
                !filters?.year
                  ? DEFAULTS_EARNERS_FILTERS_VALUE.year
                  : filters.year
              }
              menu={tokenYearsOption}
              wrapperRef={timeFilterRef}
              onToggle={() => setTimeFilterOpen(!isTimeFilterOpen)}
              property="year"
              onItemClick={updateFilter}
              iconColor="red"
            />
            <Dropdown
              open={isSortFilterOpen}
              prefix="Sort"
              position="right"
              selected={filters?.sort}
              menu={SORT_ORDER_FILTER_OPTIONS}
              wrapperRef={sortRef}
              onToggle={() => setSortFilterOpen(!isSortFilterOpen)}
              property="sort"
              onItemClick={updateFilter}
              iconColor="red"
            />
          </div>
          <div className="leader-board__list">
            {!earnerList.length && <Loader />}
            {earnerList.map((item) => (
              <div className="leader-board__item" key={item.user_id}>
                <div className="leader-board__item-left">
                  <Avatar
                    avatarUrl={item.avatar}
                    firstName={item.first_name}
                    lastInitial={item.last_initial}
                  />
                  <Label
                    className="text-ellipsis"
                    type="primary"
                    size="large"
                    font="medium"
                    title={item.label}
                  >
                    {item.label}
                  </Label>
                </div>
                <div className="leader-board__item-right">
                  {filters.order.includes("tokens") && (
                    <LogoIcon width={16.79} height={16.15} />
                  )}
                  <Label font="medium" type="primary" size="large">
                    {item.value}
                  </Label>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPage}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
        />
      </div>
      <div
        className={`leader-board-intro leader-board-intro--${
          showIntro ? "show" : "hide"
        }`}
      >
        <div className="leader-board__header">
          <Title type="primary" size="medium" font="medium">
            Definitions
          </Title>
          <Button onClick={onToggle}>
            <CloseBlackIcon className="icon__detail" />
          </Button>
        </div>
        <div>
          <div className="leader-board-intro__item">
            <Label type="primary" size="large" font="medium">
              Referrers
            </Label>
            <br />
            <Label type="primary" size="medium">
              The leaderboard recognizes Braintrust users that have earned BTRST
              by referring clients and talents to the Braintrust network. Users
              that refer clients receive 2% of their total billings in BTRST -
              up to 10k USD. Users that refer talent receive 1% of their total
              earnings in BTRST - up to 10k USD.
            </Label>
          </div>
          <div className="leader-board-intro__item">
            <Label type="primary" size="large" font="medium">
              How do I start referring clients and talents to Braintrust?
            </Label>
            <br />
            <Label type="primary" size="medium">
              Create a Braintrust account and share your unique referral code
              (available after sign-up) with clients and talent that are a great
              fit for Braintrust. You can find more information on the EARN tab
              in the Braintrust platform.
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
}
