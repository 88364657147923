import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.12 25.12"
      {...props}
    >
      <defs>
        <style>
          {
            ".cls-info-black-1{fill:none;stroke:#28282B;stroke-miterlimit:10;strokeWidth:1.29px;}.cls-info-black-2{font-size:15.4px;fill:#28282B;font-family:Graphik-Medium, Graphik Medium;font-weight:500;letter-spacing:-0.02em;}"
          }
        </style>
      </defs>

      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <circle
            className="cls-info-black-1"
            cx="12.56"
            cy="12.56"
            r="11.91"
          />
          <text className="cls-info-black-2" transform="translate(8.53 17.78)">
            {"?"}
          </text>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
