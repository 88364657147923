import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43" {...props}>
      <defs>
        <style>{".cls-logo-icon-1{fill:currentColor;}"}</style>
      </defs>
      <g data-name="Layer 2" fill="#141417" stroke="#141417">
        <path d="M22.45 32.252v-1.286a5.07 5.07 0 0 0 3.717-4.994 5.007 5.007 0 0 0-4.82-5.18l-.028-.002-.027.003a2.944 2.944 0 1 1 2.633-3.13v.004a1.135 1.135 0 0 0 1.18 1.048h.007l.007-.001a1.126 1.126 0 0 0 1.038-1.182 5.04 5.04 0 0 0-3.707-4.664v-1.223a1.116 1.116 0 1 0-2.234 0v1.225a5.06 5.06 0 0 0-3.707 4.994 5.015 5.015 0 0 0 4.808 5.18h.028l.03-.003a2.935 2.935 0 1 1-2.633 2.918 1.117 1.117 0 0 0-2.233-.005 5.06 5.06 0 0 0 3.707 5.002v1.243a1.115 1.115 0 0 0 1.117 1.164v-.4l.005.4a1.126 1.126 0 0 0 1.111-1.111Z" />
        <path d="M38.929 16.38q-.022.22.042.434a18.377 18.377 0 0 1-17.629 23.508h-.185a18.367 18.367 0 0 1 .18-36.724h.176a18.37 18.37 0 0 1 13.754 6.397l.003.003a1.125 1.125 0 0 0 1.692-1.481 20.58 20.58 0 0 0-15.425-7.18h-.211a20.6 20.6 0 0 0-.195 41.19h.212a20.61 20.61 0 0 0 20.59-20.396 20.9 20.9 0 0 0-.819-5.972l-.002-.008-.002-.008a1.116 1.116 0 0 0-1.38-.716 1.11 1.11 0 0 0-.801.953Z" />
      </g>
    </svg>
  );
}

export default SvgComponent;
