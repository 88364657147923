import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 .5A5.5 5.5 0 0 0 .5 6c0 3.036 2.464 5.5 5.5 5.5s5.5-2.464 5.5-5.5S9.036.5 6 .5M1.6 6c0-.335.044-.665.115-.979l2.63 2.629v.55c0 .605.495 1.1 1.1 1.1v1.061A4.406 4.406 0 0 1 1.6 6m7.64 2.97a1.1 1.1 0 0 0-1.046-.77h-.55V6.55a.55.55 0 0 0-.55-.55h-3.3V4.9h1.1c.303 0 .55-.248.55-.55v-1.1h1.1c.606 0 1.1-.495 1.1-1.1v-.225A4.4 4.4 0 0 1 10.4 6a4.4 4.4 0 0 1-1.16 2.97"
        fill="#141417"
      />
    </svg>
  );
}

export default SvgComponent;
