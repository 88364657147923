import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 48 1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        style={{
          enableBackground: "new 0 0 512 512",
        }}
        {...props}
      >
        <path d="M291.838 35.838 263.57 64.106l171.904 171.905H0v39.978h435.474L263.57 447.894l28.268 28.268L512 256z" />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
      </svg>
    </svg>
  );
}

export default SvgComponent;
