import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path d="M9 12V9" stroke="#141417" strokeWidth="1.5" />
      <path d="M9 5.25a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5" fill="#141417" />
      <path
        d="M9 16.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z"
        stroke="#141417"
      />
    </svg>
  );
}

export default SvgComponent;
