import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.69 1.25" {...props}>
      <defs>
        <style>
          {
            ".cls-black-line-1{fill:none;stroke:#141417;stroke-miterlimit:10;strokeWidth:1.25px;}"
          }
        </style>
      </defs>
      <g className="cls-black-line-1" data-name="Layer 2">
        <path data-name="Layer 1" d="M0 .63h20.69" />
      </g>
    </svg>
  );
}

export default SvgComponent;
