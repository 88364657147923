import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.8 36.01" {...props}>
      <defs>
        <style>{".cls-vline-1{fill:#28282b;}"}</style>
      </defs>
      <g className="cls-vline-1" data-name="Layer 2">
        <path
          d="M0 0h2.8a2 2 0 0 1 2 2v32a2 2 0 0 1-2 2H0z"
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
