import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      aria-labelledby="linkedin-in1"
      {...props}
    >
      <path d="M100.3 480H7.4V180.9h92.9zM53.8 140.1C24.1 140.1 0 115.5 0 85.8S24.1 32 53.8 32s53.8 24.1 53.8 53.8-24.1 54.3-53.8 54.3M448 480h-92.7V334.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V480h-92.8V180.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V480z" />
    </svg>
  );
}

export default SvgComponent;
