import React, { useState } from "react";

import Button from "../../Button";
import Label from "../../Label";
import Title from "../../Title";
import ButtonGroup from "../../ButtonGroup";
import { CloseWhiteIcon, DetailIcon } from "@assets/svgs";
import ButtonDropdown from "../../ButtonDropdown";
import { getGSVYears, getGSVQuaters, getGSVValue } from "../helpers";
import { numberWithCommas } from "../../../util";
import { Loader } from "../../Loader";

import "./style.scss";

export function GSV({ data, quarterlyData }) {
  const [showIntro, setShowIntro] = useState(false);

  const [selectedYear, setSelectedYear] = useState("total");
  const [selectedQuarter, setSelectedQuarter] = useState("total");
  const [openDropdown, setOpenDropdown] = useState(false);

  const years = getGSVYears(quarterlyData);
  const quarters = getGSVQuaters(quarterlyData, selectedYear);

  const currentData = getGSVValue(
    data,
    quarterlyData,
    selectedYear,
    selectedQuarter
  );

  const onToggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const onChangeYear = (_, value) => {
    setSelectedQuarter("total");
    setSelectedYear(value);
  };

  const onToggle = () => {
    setShowIntro(!showIntro);
  };

  return (
    <div className="gsv" data-aos="fade-up" data-aos-delay="50">
      <div className="gsv__overlay" />
      <div className={`gsv__main gsv__main--${showIntro ? 'hide' : 'show'}`}>
        <div className="gsv__header">
          <Title type="white" size="medium" font="medium">
            Dollars earned by the Braintrust community.
          </Title>
          <Button onClick={onToggle}>
            <DetailIcon className="icon__detail" />
          </Button>
        </div>
        {!currentData && <Loader variant="light" />}
        <div className="gsv-container gsv__content">
          {!!currentData && (
            <>
              <div className="gsv__buttons">
                <ButtonDropdown
                  open={openDropdown}
                  selected={selectedYear}
                  property="year"
                  menu={years}
                  onToggle={onToggleDropdown}
                  onItemClick={onChangeYear}
                />
                {quarters.length !== 0 && (
                  <ButtonGroup
                    value={selectedQuarter}
                    options={quarters}
                    onClick={setSelectedQuarter}
                  />
                )}
              </div>
              <p className="gsv-container__dollar-amount">
                ${numberWithCommas(Math.floor(currentData))}
              </p>
            </>
          )}
          <Label type="white" size="large">
            Represented by Braintrust's GSV{' '}
            <span className="gsv__mobile-br">(Gross Service Value)</span>
          </Label>
        </div>
      </div>
      <div className={`gsv-intro gsv-intro--${showIntro ? 'show' : 'hide'}`}>
        <div className="gsv__header">
          <Title type="white" size="medium" font="medium">
            Definitions
          </Title>
          <Button onClick={onToggle}>
            <CloseWhiteIcon className="icon__detail" />
          </Button>
        </div>
        <div className="gsv-intro__content">
          <div className="gsv-intro__item">
            <div className="gsv-intro__left">
              <Label type="white" size="large" font="medium">
                Gross Service Value
              </Label>
            </div>
            <div className="gsv-intro__right">
              <Label type="white" size="medium">
                GSV represents the actual dollar amount that has come into
                Braintrust before being distributed to the freelance talent.
                This is how Braintrust measures the network size and growth. For
                example, if a client hires three developers who are billing at
                10K/month. The GSV for the month would be $34.5k ($10k x 3 dev +
                15% BT fee).
              </Label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
