import React from "react";
import Button from "../../Button";
import Label from "../../Label";
import Title from "../../Title";

import "./style.scss";

export function Feedback() {
  const gotoFeedback = () => {
    window.open(
      "https://form.asana.com/?k=mBOjNfiL5v9PA_WMEQDX_g&d=925242810059264",
      "_blank"
    );
  };

  return (
    <div className="feedback" data-aos="fade-up" data-aos-delay="50">
      <div className="feedback__overlay" />
      <div
        className="feedback__content"
        data-aos="fade-in"
        data-aos-delay="150"
      >
        <Label size="medium" type="white">
          Feedback
        </Label>
        <Title styles="feedback__text" size="medium" font="medium" type="white">
          Are we missing something?
        </Title>
        <Button
          border="round"
          type="white"
          size="small"
          styles="btn-transparent-white"
          onClick={gotoFeedback}
        >
          Let us know!
        </Button>
      </div>
    </div>
  );
}
