import React, { useState, useEffect } from "react";
import { Navigation, NavigationMobile } from "../../components/Navigation";
import Footer from "../../components/Footer";
import {
  AverageMarketRates,
  BTRSTLeaderBoard,
  Feedback,
  GSV,
  Header,
  NetworkGrowth,
  TokenAllocation,
  UpdateInfo,
  FeeConverter,
  ProjectMetrics,
  TalentJobMetrics,
} from "../../components/Dashboard";
import { getDashboardData } from "../../actions/dashboard";
import { ProfessionalNetwork } from "../../components/Dashboard/professional-network";
import { BTRSTMarketSection } from "../../components/Dashboard/btrst-market-section";

import "./style.scss";

export function Dashboard() {
  const [data, setData] = useState();
  const [scrolling, setScrolling] = useState(false);

  const trackingScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", trackingScroll);
    getDashboardData().then((result) => {
      const { data, status_code } = result;
      if (status_code === 200) {
        setData(data);
      }
    });
  }, []);

  const gsvData = data?.dashboard ? data.dashboard.lifetime_network_gsv : null;

  return (
    <div className="dashboard__container">
      <Navigation scrolling={scrolling} />
      <NavigationMobile scrolling={scrolling} />
      <div className="dashboard__main">
        <Header />
        <UpdateInfo date={data?.dashboard.created_at} />
        <GSV data={gsvData} quarterlyData={data?.quarterly_gsv_data} />
        <NetworkGrowth gsvData={data ? data.gsv_data : undefined} />
        <FeeConverter
          totalBTRST={data?.dashboard.total_tokens_purchased}
          totalUSD={data?.dashboard.total_bt_fee}
        />
        <div className="dashboard__token-distribution">
          <TokenAllocation
            circulatingSupply={data?.dashboard.token_circulating_supply}
            totalNonZeroWallets={data?.dashboard.total_token_holders}
          />
          <ProjectMetrics
            avg_project_size={data?.dashboard.average_project_size || null}
            avg_project_duration={
              data?.dashboard.average_project_duration || null
            }
          />
        </div>
        <TalentJobMetrics
          total_jobs={data?.dashboard.total_jobs || null}
          total_clients={data?.dashboard.total_clients || null}
          total_talent={data?.dashboard.total_talent || null}
          total_proposals={data?.dashboard.total_proposals || null}
        />
        <div className="dashboard__leader-board">
          <BTRSTLeaderBoard tokenEarnerYears={data?.token_earners_years} />
          <AverageMarketRates />
        </div>

        <ProfessionalNetwork
          totalActiveUsers={data?.dashboard.feed_total_active_users}
          monthlyActiveUsers={data?.dashboard.feed_monthly_active_users}
          totalConversations={data?.dashboard.total_career_conversations}
          posts={data?.posts}
          spaces={data?.spaces}
        />

        <BTRSTMarketSection />

        <Feedback />
      </div>
      <Footer />
    </div>
  );
}
