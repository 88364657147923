import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.667 12c0-1.5 2-1.5 3-2.5.5-.5-1-.5-1-3q0-2.5 2-2.5t2 2.5c0 2.5-1.5 2.5-1 3 1 1 3 1 3 2.5"
        stroke="#141417"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M7.996 10.829c.378-.26.87-.448 1.339-.675.367-.176.721-.377.998-.654.5-.5-1-.5-1-3q0-2.5 2-2.5t2 2.5c0 2.5-1.5 2.5-1 3 1 1 3 1 3 2.5"
        stroke="#141417"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgComponent;
