import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.25 9h13.5m-4.5 4.5 4.5-4.5-4.5-4.5"
        stroke="#141417"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgComponent;
