import { useCallback, useEffect, useMemo, useState } from "react";

import { getBTRSTEarnersList } from "../../actions/dashboard";
import { numberWithCommas } from "../../util";

export const BTRST_EARNERS_FILTERS = [
  { label: "Token earned", value: "tokens" },
  { label: "GSV", value: "gsv" },
  { label: "Clients referred", value: "referred_employers" },
  { label: "Talent referred", value: "referred_users" },
  { label: "Roles hired", value: "jobs_filled" },
];

export const TIME_FILTER_OPTIONS = [{ label: "All time", value: "all" }];

export const DEFAULTS_EARNERS_FILTERS_VALUE = {
  year: "all",
  order: "-tokens",
  sort: "high-to-low",
};

export const SORT_ORDER_FILTER_OPTIONS = [
  { label: "High to Low", value: "high-to-low" },
  { label: "Low to High", value: "low-to-high" },
];

export const useEarnersList = () => {
  const [earnersList, setEarnersList] = useState([]);
  const [filters, setFilters] = useState({
    ...DEFAULTS_EARNERS_FILTERS_VALUE,
  });
  const initialCurrentPage = useMemo(
    () => (earnersList.length === 0 ? 0 : 1),
    [earnersList.length]
  );
  const [currentPage, setCurrentPage] = useState(initialCurrentPage);
  const totalEarnerCount = earnersList.length || 0;
  const pageSize = 10;
  const totalPage = Math.ceil(earnersList.length / pageSize) || 0;
  const currentData = earnersList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  const getDataItemKey = useCallback(() => {
    if (filters.order.includes("tokens")) return "total_tokens";
    if (filters.order.includes("gsv")) return "total_gsv";
    if (filters.order.includes("referred_employers"))
      return "total_referred_employers";
    if (filters.order.includes("referred_users")) return "total_referred_users";
    if (filters.order.includes("jobs_filled")) return "total_jobs_filled";
  }, [filters.order]);

  const currentDataToDisplay = currentData
    .filter((item) => Number(item[getDataItemKey()]) !== 0)
    .map((item) => {
      const itemKeyValue = getDataItemKey();

      const addFractionDigits = ["total_tokens", "total_gsv"].includes(
        itemKeyValue
      );

      return {
        label: `${item.first_name} ${item.last_initial}.`,
        value: numberWithCommas(
          Number(item[itemKeyValue]).toFixed(addFractionDigits ? 3 : 0)
        ),
        avatar: item.avatar,
        first_name: item.first_name,
        last_initial: item.last_initial,
        id: item.user_id,
      };
    });

  const adjustOrderFilter = (currentSort, defaultSort, currentOrder) => {
    if (currentSort === defaultSort && !currentOrder.startsWith("-")) {
      return `-${currentOrder}`;
    } else if (currentSort !== defaultSort && currentOrder.startsWith("-")) {
      return currentOrder.slice(1);
    }
    return currentOrder;
  };

  const updateFilter = (type, value) => {
    let newValue;
    switch (type) {
      case "year":
        newValue = { ...filters, year: value };
        break;
      case "sort":
        newValue = {
          ...filters,
          sort: value,
          order: adjustOrderFilter(
            value,
            DEFAULTS_EARNERS_FILTERS_VALUE.sort,
            filters.order
          ),
        };
        break;
      case "order":
        newValue = {
          ...filters,
          order: adjustOrderFilter(
            filters.sort,
            DEFAULTS_EARNERS_FILTERS_VALUE.sort,
            value
          ),
        };
        break;
      default:
        return;
    }
    setFilters(newValue);
    getEarnersHandler(newValue);
  };

  const getEarnersHandler = async (params) => {
    await getBTRSTEarnersList(params).then((result) => {
      const { data, status_code } = result;
      if (status_code === 200) {
        setEarnersList(data.token_earners);
      }
    });
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPage) setCurrentPage(currentPage + 1);
  };

  const totalNumberOfReferrers = totalEarnerCount
    ? numberWithCommas(totalEarnerCount)
    : 0;

  useEffect(() => {
    getEarnersHandler(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //reset page when filters will change
    setCurrentPage(initialCurrentPage);
  }, [filters, initialCurrentPage]);

  return {
    filters,
    updateFilter,
    totalNumberOfReferrers,
    currentPage,
    totalPage,
    handlePrevPage,
    handleNextPage,
    earnerList: currentDataToDisplay,
  };
};
