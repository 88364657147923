import React, { useState } from "react";
import Button from "../../Button";
import Label from "../../Label";
import Title from "../../Title";
import {
  CloseBlackIcon,
  BlackDetailIcon,
  LogoIcon,
} from "@assets/svgs";
import { numberFormatter } from "../../../util";
import { Loader } from "../../Loader";

import "./style.scss";

export function TokenAllocation({ circulatingSupply, totalNonZeroWallets }) {
  const [showIntro, setShowIntro] = useState(false);
  const maxSupply = 250000000;
  const isLoading = !circulatingSupply && !totalNonZeroWallets;

  const onToggle = () => {
    setShowIntro(!showIntro);
  };

  return (
    <div className="allocation" data-aos="fade-up" data-aos-delay="50">
      <div
        className={`allocation__main allocation__main--${
          showIntro ? "hide" : "show"
        }`}
      >
        <div className="allocation__header">
          <Title type="primary" size="small" font="medium">
            BTRST token supply and allocation.
          </Title>
          <Button onClick={onToggle}>
            <BlackDetailIcon className="icon__detail" />
          </Button>
        </div>
        {isLoading && <Loader className="allocation__loader" />}
        {!isLoading && (
          <div className="allocation__content">
            <div>
              <Label variant="primary" size="large" font="medium">
                BTRST circulating supply
              </Label>
              <Title variant="primary" size="larger" font="medium">
                {new Intl.NumberFormat().format(circulatingSupply || 0)}{" "}
              </Title>
            </div>
            <div>
              <Label variant="primary" size="large" font="medium" >
                Total BTRST Tokens
              </Label>
              <Title variant="primary" size="larger" font="medium">
                <LogoIcon className="allocation__icon" />
                {numberFormatter(maxSupply)}
              </Title>
              <Label variant="primary" size="medium">
                Fixed Supply
              </Label>
            </div>
            <div>
              <Label variant="primary" size="large" font="medium">
                Total non-zero wallets
              </Label>
              <Title variant="primary" size="larger" font="medium">
                {new Intl.NumberFormat().format(totalNonZeroWallets || 0)}{" "}
              </Title>
              <div className="allocation__link-section">
                <Link url="https://etherscan.io/token/0x799ebfabe77a6e34311eeee9825190b9ece32824#balances">
                  Ethereum
                </Link>
                &
                <Link url="https://basescan.org/token/0xa7d68d155d17cb30e311367c2ef1e82ab6022b67#balances">
                  Base
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={`allocation__intro allocation__intro--${
          showIntro ? "show" : "hide"
        }`}
      >
        <div className="allocation__header">
          <Title type="primary" size="medium" font="medium">
            Definitions
          </Title>
          <Button onClick={onToggle}>
            <CloseBlackIcon className="icon__detail" />
          </Button>
        </div>
        <div>
          <div className="allocation__intro-content">
            <Label type="primary" size="large" font="medium">
              BTRST tokens, fixed
            </Label>
          </div>
          <div>
            <Label type="primary" size="medium">
              The total number of Braintrust tokens is finite. There is a cap of
              250 million tokens of BTRST, and no more or less will ever exist.
              That means BTRST cannot be diluted. 135 million tokens are
              reserved for community incentives.
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
}

const Link = ({ url, children }) => {
  return (
    <a href={url} className="allocation__link" target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};
