import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m5.147 10.843.194-.724a.75.75 0 0 0-.53.053zM2.333 12.25l-.742-.106a.75.75 0 0 0 1.078.777zm.381-2.667.743.106a.75.75 0 0 0-.252-.673zM7 11.833c3.471 0 6.583-2.277 6.583-5.416h-1.5c0 2.015-2.11 3.916-5.083 3.916zm-2.047-.265A8 8 0 0 0 7 11.833v-1.5c-.583 0-1.14-.076-1.659-.214zM2.67 12.92l2.814-1.407-.671-1.342-2.814 1.407zm-.697-3.444-.381 2.667 1.485.212.38-2.667zM.417 6.417c0 1.475.71 2.784 1.806 3.733l.982-1.134c-.825-.715-1.288-1.631-1.288-2.6zM7 1C3.529 1 .417 3.278.417 6.417h1.5C1.917 4.4 4.027 2.5 7 2.5zm6.583 5.417C13.583 3.277 10.471 1 7 1v1.5c2.972 0 5.083 1.901 5.083 3.917z"
        fill="#141417"
      />
    </svg>
  );
}

export default SvgComponent;
