import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.24 5.68" {...props}>
      <defs>
        <style>
          {
            ".cls-arrow-down{fill:none;stroke:#28282b;stroke-miterlimit:10;strokeWidth:1.5px;}"
          }
        </style>
      </defs>
      <g className="cls-arrow-down" data-name="Layer 2">
        <path data-name="Layer 1" d="M8.71.53 4.62 4.62.53.53" />
      </g>
    </svg>
  );
}

export default SvgComponent;
