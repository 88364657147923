import React from 'react';

import Title from '../../Title';
import Display from '../../Display';

import { BlackLine } from '@assets/svgs';

import "./style.scss";

export function Header() {
  return (
    <div className="header">
      <Title type="primary" size="small" font="medium">
        The network by the numbers
      </Title>
      <Display
        type="primary"
        size="exLarge"
        styles="header__title"
        text="Network Dashboard."
      />
      <Title
        type="primary"
        size="small"
        styles="header__desc">
        <BlackLine width={25} height={2} className="header__line" />
        Braintrust is our network. Here's where we can see how it's doing.
      </Title>
    </div>
  )
}
