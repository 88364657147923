import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.79 16.15"
      {...props}
    >
      <defs>
        <style>{".cls-logo-icon-1{fill:currentColor;}"}</style>
      </defs>
      <g data-name="Layer 2">
        <path
          d="M8.4 12.07a3.9 3.9 0 0 0 2-.54 4 4 0 0 0-4-6.91 4 4 0 0 0-1.46 5.45 4 4 0 0 0 3.46 2M5.79 7.38a2.7 2.7 0 0 1 4.94-.65 2.69 2.69 0 1 1-4.94.65m7.1-1.9a3.87 3.87 0 0 0 2.36 1.81 3.8 3.8 0 0 0 1 .13 5 5 0 0 0 .53 0 10 10 0 0 0-.2-1.25 2.7 2.7 0 0 1-1-.06 2.62 2.62 0 0 1-1.85-3.21 2.6 2.6 0 0 1 .45-.9 11 11 0 0 0-1-.8 3.85 3.85 0 0 0-.31 4.33Zm2.36 3.38a3.89 3.89 0 0 0-2.75 4.76 3.9 3.9 0 0 0 .7 1.38 9.5 9.5 0 0 0 1-.8 2.5 2.5 0 0 1-.46-.91 2.62 2.62 0 0 1 1.85-3.21 2.7 2.7 0 0 1 1-.06 10 10 0 0 0 .2-1.24 3.8 3.8 0 0 0-1.54.08m-6.86 5.67a2.6 2.6 0 0 1 2.41 1.62 8 8 0 0 0 1.2-.46 3.88 3.88 0 0 0-7.19 0 8 8 0 0 0 1.19.46 2.59 2.59 0 0 1 2.39-1.62m0-11.65A3.89 3.89 0 0 0 12 .46 8 8 0 0 0 10.8 0a2.6 2.6 0 0 1-2.41 1.62A2.59 2.59 0 0 1 6 0a8 8 0 0 0-1.2.46 3.88 3.88 0 0 0 3.59 2.42M3.9 10.67a3.87 3.87 0 0 0-2.36-1.81A3.8 3.8 0 0 0 0 8.78 8.4 8.4 0 0 0 .2 10h.33a2.6 2.6 0 0 1 .68.09A2.6 2.6 0 0 1 2.8 11.3a2.6 2.6 0 0 1-.19 2.89 8.4 8.4 0 0 0 1 .81 3.9 3.9 0 0 0 .29-4.33M1.54 7.29a3.87 3.87 0 0 0 2.74-4.75 3.7 3.7 0 0 0-.69-1.39 11 11 0 0 0-1 .8 2.6 2.6 0 0 1 .46.91 2.63 2.63 0 0 1-1.84 3.21 2.6 2.6 0 0 1-1 .06A8.4 8.4 0 0 0 0 7.38a5 5 0 0 0 .53 0 3.8 3.8 0 0 0 1.01-.09"
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
