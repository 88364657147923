import * as React from "react";

function SvgComponent(props) {
  return (
      <svg
        viewBox="0 0 120 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <defs>
          <style>{".cls-logo-icon-1{fill:currentColor;}"}</style>
        </defs>
        <path
          d="M0 29.219h115.409M90.853 56.686 116 29.716 90.853 2"
          stroke="#fff"
          strokeWidth="5.8"
        />
      </svg>
  );
}

export default SvgComponent;
