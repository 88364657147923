import React, { useState, useEffect, useRef } from "react";

import { TabCarousel } from "../../TabCarousel";
import Button from "../../Button";
import Dropdown from "../../Dropdown";
import Label from "../../Label";
import Title from "../../Title";
import { CloseBlackIcon, BlackDetailIcon } from "@assets/svgs";
import { regions } from "../../../constants";
import {
  SORTS_SKILLS_OPTIONS,
  useMarketRatesForSkills,
} from "../../../hooks/market-rates-skills";
import { Loader } from "../../Loader";
import { Pagination } from "./pagination";

import "./style.scss";

const tabList = [
  { label: "All", value: "all" },
  { label: "Engineering", value: "Engineering" },
  { label: "Design", value: "Design" },
  { label: "Product", value: "Product" },
  { label: "Marketing", value: "Marketing" },
  {
    label: "Project/Program Management",
    value: "Project & Program Management",
  },
];

export function AverageMarketRates() {
  const {
    currentData,
    currentPage,
    totalPage,
    filters,
    handleNextPage,
    handlePrevPage,
    updateFilter,
  } = useMarketRatesForSkills();
  const [openRegion, setOpenRegion] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [showIntro, setShowIntro] = useState(false);

  const regionRef = useRef();
  const sortRef = useRef();

  const handleClickOutside = (event) => {
    if (openRegion && regionRef && !regionRef.current.contains(event.target))
      setOpenRegion(false);
    if (openSort && sortRef && !sortRef.current.contains(event.target))
      setOpenSort(false);
  };

  const onToggle = () => {
    setShowIntro(!showIntro);
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openRegion, openSort]);

  return (
    <div
      className="leader-board leader-board--violet"
      data-aos="fade-up"
      data-aos-delay="1"
    >
      <div
        className={`leader-board__main leader-board__main--${
          showIntro ? "hide" : "show"
        }`}
      >
        <div className="leader-board__header">
          <Title
            styles="leader-board__title"
            type="primary"
            size="small"
            font="medium"
          >
            Average market rates
          </Title>
          <Button onClick={onToggle}>
            <BlackDetailIcon className="icon__detail" />
          </Button>
        </div>
        <div>
          <TabCarousel
            list={tabList}
            selected={filters.role}
            property="role"
            onTabItemClick={updateFilter}
          />
          <div className="leader-board__filter">
            <Dropdown
              open={openRegion}
              selected={filters.location}
              menu={regions}
              wrapperRef={regionRef}
              onToggle={() => setOpenRegion(!openRegion)}
              property="location"
              onItemClick={updateFilter}
              iconColor="blue"
            />
            <Dropdown
              open={openSort}
              prefix="Sort"
              position="right"
              selected={filters.order}
              menu={SORTS_SKILLS_OPTIONS}
              wrapperRef={sortRef}
              onToggle={() => setOpenSort(!openSort)}
              property="order"
              onItemClick={updateFilter}
              iconColor="blue"
            />
          </div>
          <div className="leader-board__list">
            {!currentData.length && <Loader />}
            {currentData.map((item) => {
              return (
                <div
                  className="leader-board__item"
                  key={`${item.skill}-${item.avg_rate}-${item.root_role}`}
                >
                  <div className="leader-board__item-left">
                    <Label
                      type="primary"
                      size="large"
                      styles="text-ellipsis"
                      title={item.skill}
                    >
                      {item.skill}
                    </Label>
                  </div>
                  <div className="leader-board__item-right">
                    <Label type="primary" size="large" font="medium">
                      ${Math.ceil(item.avg_rate)}/hr USD
                    </Label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPage}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
        />
      </div>
      <div
        className={`leader-board-intro leader-board-intro--${
          showIntro ? "show" : "hide"
        }`}
      >
        <div className="leader-board__header">
          <Title type="primary" size="medium" font="medium">
            Definitions
          </Title>
          <Button onClick={onToggle}>
            <CloseBlackIcon className="icon__detail" />
          </Button>
        </div>
        <div className="leader-board-intro__item">
          <Label type="primary" size="large" font="medium">
            Average Skill Market Rates
          </Label>
          <br />
          <Label type="primary" size="medium">
            Skill Rates are calculated from projects that have hired Braintrust
            talent. Using the tagged skills on the job list, the hired date, and
            the location of the talent, this metric calculates averages and
            creates a market rate. As additional jobs are added to the platform,
            this rate will continue to get more granular.
          </Label>
        </div>
      </div>
    </div>
  );
}
