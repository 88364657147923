import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.99 19.86"
      {...props}
    >
      <defs>
        <style>
          {
            ".cls-chevron-right-active-1{fill:none;stroke:#28282b;stroke-miterlimit:10;strokeWidth:1.5px;}"
          }
        </style>
      </defs>
      <g className="cls-chevron-right-active-1" data-name="Layer 2">
        <path data-name="Layer 1" d="m.53.53 9.4 9.4-9.4 9.4" />
      </g>
    </svg>
  );
}

export default SvgComponent;
