import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { BlackInfoIcon } from '@assets/svgs';
import Label from '../../Label';

import "./style.scss";

dayjs.extend(utc)
dayjs.extend(timezone)

export function UpdateInfo({ date }) {
  if (!date || !dayjs(date).isValid()) {
    return <Label size="medium">Loading...</Label>;
  }

  return (
    <Label size="medium" className="update-info">
      <BlackInfoIcon className="icon__detail icon--text-right" />
      Updated <strong>{dayjs(date + 'Z').tz("EST").format('MMM D, YYYY [at] h:mm A')} EST</strong>. Updates every 4 hours.
    </Label>
  )
}
